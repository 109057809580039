import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSetRecoilState } from "recoil";
import { modalState } from "../recoil/modalState";
import { useAPI } from "./API";

// Styled components (unchanged)
const Wrapper = styled.div<{ height?: string; fontSize?: string }>`
  width: 100%;
  height: ${(props) => props.height || "300px"};
  min-height: 50px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize || "16px"};
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  z-index: 2;
`;

const IndexNumber = styled.div`
  color: #4e4e59;
  font-size: 16px;
  font-weight: bold;
`;

const FavoriteIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #4e4e59;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #f44336;
    transform: scale(1.1);
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
`;

const Question = styled.div<{ fontSize?: string }>`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: ${(props) => props.fontSize || "inherit"};
  padding: 40px 10px 10px;
  text-align: center;
  overflow-y: auto;
  user-select: text;
`;

const Answer = styled.div<{ fontSize?: string }>`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: ${(props) => props.fontSize || "inherit"};
  padding: 10px;
  text-align: center;
  overflow-y: auto;
  user-select: text;
`;

const AnswerWrapper = styled.div`
  color: red;
  user-select: text;
  text-align: left;
`;

const PronunciationWrapper = styled.div`
  color: #808080;
  font-size: 1em;
  margin-top: 5px;
  user-select: text;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
`;

const AnswerButton = styled.button<{
  backgroundColor: string;
  color: string;
  radius: string;
}>`
  width: 50%;
  height: 100%;
  border-radius: ${(props) => props.radius};
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.backgroundColor === "#F44336"
        ? "#D32F2F"
        : props.backgroundColor === "#4CAF50"
        ? "#388E3C"
        : props.backgroundColor};
  }
`;

const ButtonText = styled.span`
  font-size: 18px;
  color: inherit;
  margin-bottom: 5px;
`;

const KeyText = styled.span`
  font-size: 12px;
  opacity: 0.8;
  color: inherit;
`;

const InfoWrapper = styled.div`
  color: #808080;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoItem = styled.span`
  margin: 0 5px;
`;

const Separator = styled.span`
  margin: 0 5px;
`;

const CategoryLabel = styled.div`
  font-size: 12px;
  color: #888;
  margin-left: 10px;
`;

const TopBarContent = styled.div`
  display: flex;
  align-items: center;
`;

interface CardProps {
  index: number;
  _id: string;
  question: string;
  answer: string;
  correctCount?: number;
  incorrectCount?: number;
  lastAnswered?: string;
  onDelete?: (_id: string) => void;
  countCorrect?: (_id: string, isCorrect: boolean) => void;
  fetchPronunciation: (answer: string) => Promise<string | null>;
  height?: string;
  fontSize?: string;
  showDelete?: boolean;
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  pronunciation: string | null;
  category: string;
}

function Card({
  index,
  _id,
  question,
  answer,
  correctCount: initialCorrectCount = 0,
  incorrectCount: initialIncorrectCount = 0,
  lastAnswered: initialLastAnswered,
  onDelete,
  countCorrect,
  fetchPronunciation,
  height,
  fontSize,
  showDelete = true,
  isShow,
  setIsShow,
  pronunciation: initialPronunciation,
  category,
}: CardProps) {
  const [cardState, setCardState] = useState({
    correctCount: initialCorrectCount,
    incorrectCount: initialIncorrectCount,
    lastAnswered: initialLastAnswered,
    pronunciation: initialPronunciation,
  });

  const setModal = useSetRecoilState(modalState);
  const { updateQnACount } = useAPI();

  const handleAnswer = useCallback(
    (isCorrect: boolean) => {
      if (!isShow) {
        setIsShow(true);
        setCardState((prevState) => ({
          ...prevState,
          correctCount: prevState.correctCount + (isCorrect ? 1 : 0),
          incorrectCount: prevState.incorrectCount + (isCorrect ? 0 : 1),
          lastAnswered: new Date().toISOString(),
        }));

        Promise.all([
          updateQnACount(_id, isCorrect),
          fetchPronunciation(answer),
        ]).then(([_, pronunciation]) => {
          if (pronunciation) {
            setCardState((prevState) => ({
              ...prevState,
              pronunciation,
            }));
          }
        });

        if (countCorrect) {
          countCorrect(_id, isCorrect);
        }
      }
    },
    [
      isShow,
      _id,
      answer,
      updateQnACount,
      fetchPronunciation,
      countCorrect,
      setIsShow,
    ]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isShow) {
        if (event.key === "ArrowLeft") {
          handleAnswer(false);
        } else if (event.key === "ArrowRight") {
          handleAnswer(true);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAnswer, isShow]);

  const handleDeleteClick = useCallback(() => {
    setModal({
      isOpen: true,
      title: "삭제 확인",
      message: "정말 삭제하시겠습니까? (삭제 시 복구가 불가능합니다.)",
      onConfirm: () => {
        if (onDelete) {
          onDelete(_id);
        }
        setModal((prev) => ({ ...prev, isOpen: false }));
      },
    });
  }, [_id, onDelete, setModal]);

  const renderQuestion = () => {
    if (!question) return null;

    // 불필요한 공백을 제거하고 \n과 \r\n을 적절히 처리
    const processedQuestion = question
      .replace(/\\n/g, "\\n") // 이스케이프된 \n은 그대로 보여주기
      .replace(/\\r/g, "\\r"); // 이스케이프된 \r은 그대로 보여주기

    return (
      <>
        {processedQuestion.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line.trim()} {/* 각 라인에서 불필요한 공백 제거 */}
            {index !== processedQuestion.split("\n").length - 1 && <br />}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderAnswer = () => {
    if (!answer) return null;

    // 불필요한 공백을 제거하고 \n과 \r\n을 적절히 처리
    const processedAnswer = answer
      .replace(/\\n/g, "\\n") // 이스케이프된 \n은 그대로 보여주기
      .replace(/\\r/g, "\\r"); // 이스케이프된 \r은 그대로 보여주기

    return (
      <>
        <AnswerWrapper>
          {processedAnswer.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line.trim()} {/* 각 라인에서 불필요한 공백 제거 */}
              {index !== processedAnswer.split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </AnswerWrapper>

        {/* 발음기호를 그대로 유지 */}
        {cardState.pronunciation && (
          <PronunciationWrapper>
            {cardState.pronunciation.split("\n").map((line, index) => (
              <React.Fragment key={`pron-${index}`}>
                {line}
                {/* pronunciation이 null이 아니므로 안전하게 처리 */}
                {index !== cardState.pronunciation!.split("\n").length - 1 && (
                  <br />
                )}
              </React.Fragment>
            ))}
          </PronunciationWrapper>
        )}
      </>
    );
  };

  return (
    <Wrapper height={height} fontSize={fontSize}>
      <TopBar>
        <TopBarContent>
          <IndexNumber>{index}</IndexNumber>
          <CategoryLabel>{category}</CategoryLabel>
        </TopBarContent>
        {showDelete && (
          <FavoriteIconWrapper>
            <TrashIcon icon={faTrashAlt} onClick={handleDeleteClick} />
          </FavoriteIconWrapper>
        )}
      </TopBar>
      <ContentArea>
        <Question fontSize={fontSize}>
          {renderQuestion()}
          {cardState.lastAnswered && (
            <InfoWrapper>
              <InfoItem>
                Last: {new Date(cardState.lastAnswered).toLocaleDateString()}
              </InfoItem>
              <Separator>·</Separator>
              <InfoItem>
                Count: {cardState.correctCount + cardState.incorrectCount}
              </InfoItem>
            </InfoWrapper>
          )}
        </Question>
        {isShow ? (
          <Answer fontSize={fontSize}>{renderAnswer()}</Answer>
        ) : (
          <ButtonWrapper>
            <AnswerButton
              onClick={() => handleAnswer(false)}
              backgroundColor="#F44336"
              color="#ffffff"
              radius="0 0 0 10px"
            >
              <ButtonText>몰라</ButtonText>
              <KeyText>← 방향키</KeyText>
            </AnswerButton>
            <AnswerButton
              onClick={() => handleAnswer(true)}
              backgroundColor="#4CAF50"
              color="#ffffff"
              radius="0 0 10px 0"
            >
              <ButtonText>생각났다!</ButtonText>
              <KeyText>방향키 →</KeyText>
            </AnswerButton>
          </ButtonWrapper>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default React.memo(Card);
