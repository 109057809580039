import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

// 애니메이션 정의
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const divineLight = keyframes`
  0%, 100% { 
    opacity: 0;
    transform: scale(1);
  }
  50% { 
    opacity: 1;
    transform: scale(1.2);
  }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0px); }
`;

const hellFire = keyframes`
  0% { transform: translateY(0) scale(1); }
  50% { transform: translateY(-10px) scale(1.1); }
  100% { transform: translateY(0) scale(1); }
`;

// 오버레이 스타일
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); // 반투명 검은색 배경
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  font-weight: bold;
  z-index: 100; // 가장 상위에 위치하도록 설정
`;

// HOFWrapper 스타일 정의
const HOFWrapper = styled.div`
  height: 95%;
  padding: 20px;
  background: linear-gradient(to bottom, #ffffff, #ffd700, #ff4500, #8b0000);
  overflow: hidden;
  color: #4b3621;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      ellipse at top,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    pointer-events: none;
    animation: ${divineLight} 5s infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    pointer-events: none;
    animation: ${divineLight} 7s infinite 1s;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// 나머지 styled-components 정의
const HellFireEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #ff4500, #8b0000);
  opacity: 0.6;
  filter: blur(10px);
  animation: ${hellFire} 3s ease-in-out infinite;
  z-index: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 50px;
    height: 50px;
    background: #ff4500;
    border-radius: 50%;
    filter: blur(5px);
  }

  &::before {
    left: 10%;
    animation: ${hellFire} 4s ease-in-out infinite 0.5s;
  }

  &::after {
    right: 10%;
    animation: ${hellFire} 3.5s ease-in-out infinite 0.75s;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 3.5em;
  color: #4b3621;
  text-shadow: 2px 2px 4px rgba(255, 215, 0, 0.5);
  font-family: "Cinzel", serif;
  letter-spacing: 3px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 2.5em;
  }
`;

const DateTimeWrapper = styled.div`
  text-align: center;
  font-size: 1.3em;
  color: #b8860b;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  position: relative;
  z-index: 1;
  font-family: "Lato", sans-serif;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const TableContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const RefreshButton = styled.button`
  position: absolute;
  top: -130px;
  right: 0;
  background: #ffd700;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;

  &:hover {
    background: #ffed4a;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingRefreshIcon = styled.svg<{ $isRefreshing: boolean }>`
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #4b3621;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: ${(props) =>
    props.$isRefreshing
      ? css`
          ${rotateAnimation} 1s linear infinite
        `
      : "none"};
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  animation: ${fadeIn} 0.8s ease-out;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    border-spacing: 0 6px;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: center;
  background-color: rgba(75, 54, 33, 0.1);
  color: #4b3621;
  font-size: 1em;
  font-weight: 600;
  font-family: "Cinzel", serif;
  text-shadow: 1px 1px 2px rgba(255, 215, 0, 0.3);

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.8em;
  }
`;

const TableRow = styled.tr<{ $rank: number }>`
  background: ${(props) =>
    props.$rank === 1
      ? "rgba(255, 215, 0, 0.6)"
      : props.$rank === 2
      ? "rgba(192, 192, 192, 0.6)"
      : props.$rank === 3
      ? "rgba(205, 127, 50, 0.6)"
      : "rgba(255, 255, 255, 0.3)"};
  transition: all 0.3s ease;

  ${(props) =>
    props.$rank === 1 &&
    css`
      animation: ${float} 3s ease-in-out infinite;
    `}

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  padding: 12px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #4b3621;
  text-align: center;
  font-size: 0.9em;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.8em;
  }
`;

const UsernameCell = styled(TableCell)<{ $rank: number }>`
  font-weight: 700;
  font-size: ${(props) => (props.$rank <= 3 ? "1.1em" : "0.9em")};
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: ${(props) => (props.$rank <= 3 ? "0.9em" : "0.8em")};
  }
`;

const RankCell = styled(TableCell)`
  font-size: 1.1em;
  font-weight: 700;
  color: #4b3621;
  font-family: "Cinzel", serif;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const ScoreCell = styled(TableCell)`
  font-weight: 700;
  color: #4b3621;
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Crown = styled.span`
  font-size: 1.2em;
  vertical-align: middle;
  margin: 0 5px;
`;

const LoadingMessage = styled.div`
  color: #4b3621;
  font-size: 18px;
  text-align: center;
  padding: 20px;
`;

const VersionText = styled.div`
  text-align: center;
  font-size: 1.2em;
  color: #4b3621; /* 검은색으로 설정 */
  font-family: "Cinzel", serif;
  margin-top: 20px;
  opacity: 1; /* 불투명하게 수정 */
  text-shadow: none; /* 텍스트 그림자 제거 */
  position: relative;
  z-index: 100; /* 최상위로 위치하게 z-index 조정 */

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

// 컴포넌트 인터페이스 정의
interface Ranking {
  username: string;
  totalAnswered: number;
  answeredToday: number;
  cardCount: number;
}

interface HOFProps {
  rankings: Ranking[];
  onRefresh: () => Promise<void>;
}

const HOF: React.FC<HOFProps> = ({ rankings, onRefresh }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false); // 오버레이 상태

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (rankings.length > 0) {
      setIsLoading(false);
    }
  }, [rankings]);

  const formatDate = (date: Date) => {
    return format(date, "yyyy-MM-dd HH:mm:ss", { locale: ko });
  };

  const getNextResetTime = () => {
    const nextReset = new Date(currentTime);
    nextReset.setHours(24, 0, 0, 0);
    return formatDate(nextReset);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await onRefresh();
    setIsRefreshing(false);
  };

  return (
    <HOFWrapper>
      {/* 주석처리해서 쉽게 오버레이 켜고 끌 수 있음 */}
      {showOverlay && <Overlay>현재 페이지는 점검 중입니다</Overlay>}
      <TitleContainer>
        <Title>Hall of Fame</Title>
        <DateTimeWrapper>
          {formatDate(currentTime)}&nbsp;&nbsp;~&nbsp;&nbsp;{getNextResetTime()}
        </DateTimeWrapper>
      </TitleContainer>
      <TableContainer>
        <RefreshButton onClick={handleRefresh} disabled={isRefreshing}>
          <RotatingRefreshIcon viewBox="0 0 24 24" $isRefreshing={isRefreshing}>
            <path d="M1 20v-6h6" />
            <path d="M3.51 9a9 9 0 1 1 2.13 9.36L1 14" />
          </RotatingRefreshIcon>
        </RefreshButton>
        {isLoading ? (
          <LoadingMessage>랭킹 정보를 불러오는 중...</LoadingMessage>
        ) : (
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Rank</TableHeader>
                <TableHeader>Username</TableHeader>
                <TableHeader>Today Solved</TableHeader>
                <TableHeader>Total Solved</TableHeader>
                <TableHeader>Total Cards</TableHeader>
              </tr>
            </thead>
            <tbody>
              {rankings.map((ranking, index) => (
                <TableRow key={index} $rank={index + 1}>
                  <RankCell>{index + 1}</RankCell>
                  <UsernameCell $rank={index + 1}>
                    {index === 0 && <Crown>👑</Crown>}
                    {ranking.username}
                    {index === 0 && <Crown>👑</Crown>}
                  </UsernameCell>
                  <ScoreCell>
                    {ranking.answeredToday?.toLocaleString() ?? "N/A"}
                  </ScoreCell>
                  <TableCell>
                    {ranking.totalAnswered?.toLocaleString() ?? "N/A"}
                  </TableCell>
                  <TableCell>
                    {ranking.cardCount?.toLocaleString() ?? "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </RankingTable>
        )}
      </TableContainer>
      <HellFireEffect />
      <VersionText>armgi v0.4.3</VersionText>
      <VersionText>pronunciation_v003</VersionText>
    </HOFWrapper>
  );
};

export default HOF;
