import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { useAPI } from "./API";

const ConverterWrapper = styled.div`
  height: 95%;
  background-color: #0e0f10;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const InputMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`;

const TextInput = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 49%;
  resize: none;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  spell-check: false; // 추가
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 70px;
  border-radius: 0 0 10px 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #01e795;
  color: #0e0f10;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #00c278;
  }
`;

const ResultOutput = styled.pre`
  padding: 10px;
  width: 100%;
  height: 49%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  overflow-y: auto;
  background-color: #f8f8f8;
  white-space: pre-wrap;
  word-wrap: break-word;
  user-select: text;
  cursor: text;
`;

function PronunciationConverter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const { fetchPronunciation } = useAPI();

  const handleConvert = async () => {
    try {
      const pronunciation = await fetchPronunciation(inputText);
      if (pronunciation) {
        setOutputText(pronunciation);
      } else {
        setOutputText("발음을 찾을 수 없습니다.");
      }
    } catch (error) {
      console.error("Error fetching pronunciation:", error);
      setOutputText("발음 변환 중 오류가 발생했습니다.");
    }
  };

  return (
    <ConverterWrapper>
      <ContentWrapper>
        <InputMainWrapper>
          <InputWrapper>
            <TextInput
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="영어 문장을 입력하세요."
            />
            <ResultOutput>{outputText}</ResultOutput>
          </InputWrapper>
        </InputMainWrapper>
        <StyledButton onClick={handleConvert}>
          변환
        </StyledButton>
      </ContentWrapper>
    </ConverterWrapper>
  );
}

export default PronunciationConverter;