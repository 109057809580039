import React, { useState, useEffect, useCallback, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Button from "./Button";
import QnAList from "./QnAList";
import QnAAdd from "./QnAAdd";
import RandomCard from "./RandomCard";
import HOF from "./HOF";
import AdminPanel from "./AdminPanel";
import PronunciationConverter from "./PronunciationConverter";
import { useAPI } from "./API";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../recoil/authState";
import logoutIcon from "./img/out.png";

const Wrapper = styled.section`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SubWrapper = styled.div`
  width: 65%;
  min-width: 600px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100%;
    min-width: 300px;
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  height: 5%;
  display: flex;
  flex-direction: row;
  min-height: 25px;
  align-items: center;
`;

const ActiveBar = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  background-color: #0e0f10;
  color: #ffffff;
  position: relative;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 0;
  flex-grow: 1;
  outline: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${(props) =>
    props.isActive &&
    `
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

const goldShine = keyframes`
  0% {
    text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  }
  50% {
    text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700, 0 0 40px #ffd700;
  }
  100% {
    text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  }
`;

const HOFButton = styled(StyledButton)`
  color: #ffd700;
  font-weight: bold;
  animation: ${goldShine} 2s infinite;
`;

const AdminButton = styled(StyledButton)`
  background-color: #0e0f10;
  color: white;
`;

const LogoutButton = styled.button`
  background-color: #0e0f10;
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 100%;
  img {
    width: 20px;
    height: 20px;
  }
`;

const PronunciationButton = styled(StyledButton)`
  background-color: #0e0f10;
  color: white;
`;


interface QnAItem {
  _id: string;
  question: string;
  answer: string;
  category: string;
  correctCount?: number;
  incorrectCount?: number;
  lastAnswered?: string;
}


function MainPage() {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const {
    fetchQnA,
    addQnA,
    deleteQnA,
    updateQnACount,
    fetchRankings,
  } = useAPI();

  const [isQnA, setQuestion] = useState("random");
  const [qnaList, setQnaList] = useState<QnAItem[]>([]);
  const [rankings, setRankings] = useState([]);
  const isInitialMount = useRef(true);
  const pronunciationClick = () => setQuestion("pronunciation");

  const loadQnA = useCallback(async () => {
    const data = await fetchQnA();
    if (JSON.stringify(data) !== JSON.stringify(qnaList)) {
      setQnaList(data);
    }
  }, [fetchQnA, qnaList]);

  const loadRankings = useCallback(async () => {
    const data = await fetchRankings();
    setRankings(data);
  }, [fetchRankings]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login");
    } else if (isInitialMount.current) {
      loadQnA();
      loadRankings();
      isInitialMount.current = false;
    }
  }, [auth.isAuthenticated, navigate, loadQnA, loadRankings]);

  const handleAddSuccess = useCallback(() => {
    loadQnA();
  }, [loadQnA]);

  const handleBulkAddQnA = useCallback(() => {
    loadQnA();
  }, [loadQnA]);

  const addClick = () => setQuestion("add");
  const listClick = () => setQuestion("list");
  const randomClick = () => setQuestion("random");
  const hofClick = () => setQuestion("hof");
  const adminClick = () => setQuestion("admin");
  const logoutClick = () => {
    navigate("/login");
  };

  const handleDelete = async (_id: string) => {
    await deleteQnA(_id);
    loadQnA();
  };

  const countCorrect = async (_id: string, isCorrect: boolean) => {
    await updateQnACount(_id, isCorrect);
    loadQnA();
    loadRankings();
  };

  const refreshList = useCallback(() => {
    loadQnA();
  }, [loadQnA]);

  const refreshRankings = useCallback(async () => {
    await loadRankings();
  }, [loadRankings]);

  const isAdmin = auth.user?.username === "armbjs";

  const calculateWidth = (text: string) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "12px Arial";
      const metrics = context.measureText(text);
      return `${metrics.width}px`;
    }
    return "100%";
  };

  return (
    <Wrapper>
      <SubWrapper>
        <TitleWrapper>
          <StyledButton
            isActive={isQnA === "add"}
            width={isAdmin ? "16.67%" : "20%"}
            fontsize={12}
            height="100%"
            onClick={addClick}
            disableHover={true}
          >
            카드추가
            {isQnA === "add" && <ActiveBar width={calculateWidth("AddCard")} />}
          </StyledButton>
          <StyledButton
            isActive={isQnA === "random"}
            width={isAdmin ? "16.67%" : "20%"}
            fontsize={12}
            height="100%"
            onClick={randomClick}
            disableHover={true}
          >
            플래시카드
            {isQnA === "random" && (
              <ActiveBar width={calculateWidth("FlashCard")} />
            )}
          </StyledButton>
          <StyledButton
            isActive={isQnA === "list"}
            width={isAdmin ? "16.67%" : "20%"}
            fontsize={12}
            height="100%"
            onClick={listClick}
            disableHover={true}
          >
            카드목록
            {isQnA === "list" && (
              <ActiveBar width={calculateWidth("CardList")} />
            )}
          </StyledButton>
          <PronunciationButton
            isActive={isQnA === "pronunciation"}
            width={isAdmin ? "16.67%" : "20%"}
            fontsize={12}
            height="100%"
            onClick={pronunciationClick}
            disableHover={true}
          >
            발음변환
            {isQnA === "pronunciation" && (
              <ActiveBar width={calculateWidth("발음")} />
            )}
          </PronunciationButton>
          <HOFButton
            isActive={isQnA === "hof"}
            width={isAdmin ? "16.67%" : "20%"}
            fontsize={12}
            height="100%"
            onClick={hofClick}
            disableHover={true}
          >
            HOF
          </HOFButton>
          {isAdmin && (
            <AdminButton
              isActive={isQnA === "admin"}
              width="16.67%"
              fontsize={12}
              height="100%"
              onClick={adminClick}
              disableHover={true}
            >
              관리
            </AdminButton>
          )}
          <LogoutButton onClick={logoutClick}>
            <img src={logoutIcon} alt="Logout" />
          </LogoutButton>
        </TitleWrapper>

        {isQnA === "list" ? (
          <QnAList
            qnaList={qnaList}
            onDelete={handleDelete}
            countCorrect={countCorrect}
            refreshList={refreshList}
          />
        ) : isQnA === "add" ? (
          <QnAAdd onAddSuccess={handleAddSuccess} onBulkAddQnA={handleBulkAddQnA} />
        ) : isQnA === "hof" ? (
          <HOF rankings={rankings} onRefresh={refreshRankings} />
        ) : isQnA === "admin" && isAdmin ? (
          <AdminPanel />
        ) : isQnA === "pronunciation" ? (
          <PronunciationConverter />
        ) : (
          <RandomCard
            onDelete={handleDelete}
            countCorrect={countCorrect}
          />
        )}
      </SubWrapper>
    </Wrapper>
  );
}

export default MainPage;