import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import Card from "./Card";
import { useAPI } from "./API";

const ListWrapper = styled.div`
  height: 95%;
  background-color: #0e0f10;
  display: flex;
  flex-direction: column;
`;

const ControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0 10px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButton = styled.input`
  margin-right: 5px;
`;

const RadioLabel = styled.label`
  color: #ffffff;
  margin-right: 15px;
  cursor: pointer;
`;

const CategoryDropdown = styled.select`
  padding: 5px;
  border-radius: 4px;
`;

const RangeInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RangeInput = styled.input`
  width: 45px;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #01e795;
  border-radius: 4px;
  background-color: #1e1f21;
  color: #ffffff;
`;

const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const NavButton = styled.button<{ $disabled?: boolean }>`
  flex: 1;
  height: 70px;
  background-color: ${props => props.$disabled ? '#8395a7' : '#01e795'};
  border: none;
  color: ${props => props.$disabled ? '#576574' : '#000000'};
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.$disabled ? '#8395a7' : '#00c278'};
  }
`;

const RefreshButton = styled(NavButton)`
  background-color: ${props => props.$disabled ? '#8395a7' : '#d3d4d8'};
  color: #000000;
  &:hover:not(:disabled) {
    background-color: ${props => props.$disabled ? '#8395a7' : '#b5b7ba'};
  }
`;

const ButtonText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: inherit;
  margin-right: 8px;
`;

const KeyText = styled.span`
  font-size: 14px;
  opacity: 0.8;
  color: inherit;
`;

const LoadingMessage = styled.div`
  color: #ffffff;
  font-size: 18px;
  text-align: center;
`;

const EmptyMessage = styled.div`
  color: #ffffff;
  font-size: 18px;
  text-align: center;
`;

interface CardState extends QnAItem {
  pronunciation?: string | null;
}

interface QnAItem {
  _id: string;
  question: string;
  answer: string;
  category: string;
  correctCount?: number;
  incorrectCount?: number;
  lastAnswered?: string;
}

// CardState 인터페이스 정의
interface CardState extends QnAItem {
  pronunciation?: string | null;
}

interface RandomCardProps {
  onDelete: (_id: string) => void;
  countCorrect: (_id: string, isCorrect: boolean) => void;
}

const BASE_URL = "http://158.247.215.166:5000";

// fetchQnA 함수 정의
const fetchQnA = async (): Promise<QnAItem[]> => {
  const response = await fetch(`${BASE_URL}/qna`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) throw new Error("Network response was not ok");
  const data = await response.json();
  return data.map((item: any) => ({
    ...item,
    category: item.category || 'none category'
  }));
};

function RandomCard({ onDelete, countCorrect }: RandomCardProps) {
  const { fetchPronunciation } = useAPI();
  const [qnaList, setQnaList] = useState<QnAItem[]>([]);
  const [categories, setCategories] = useState<string[]>(['all']);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [filteredList, setFilteredList] = useState<QnAItem[]>([]);

  const [currentCard, setCurrentCard] = useState<QnAItem | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isReversed, setIsReversed] = useState<boolean>(false);
  const [startRange, setStartRange] = useState<string>("");
  const [endRange, setEndRange] = useState<string>("");
  const [cardStates, setCardStates] = useState<{ [key: string]: CardState }>({});
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadQnaList = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchQnA();
      setQnaList(data);
      const uniqueCategories = Array.from(new Set(data.map(item => item.category)));
      setCategories(['all', ...uniqueCategories]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading QnA list:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadQnaList();
  }, [loadQnaList]);

  useEffect(() => {
    let filtered = selectedCategory === 'all'
      ? qnaList
      : qnaList.filter(item => item.category === selectedCategory);
  
    // 범위 선택 적용
    if (startRange && endRange) {
      const start = parseInt(startRange);
      const end = parseInt(endRange);
      if (!isNaN(start) && !isNaN(end) && start <= end && start > 0) {
        filtered = filtered.slice(start - 1, end);
      }
    }
  
    setFilteredList(filtered);
  }, [qnaList, selectedCategory, startRange, endRange]);

  useEffect(() => {
    if (filteredList.length > 0) {
      setCurrentCard(filteredList[0]);
      setCurrentIndex(0);
      setIsShow(false);
    } else {
      setCurrentCard(null);
    }
  }, [filteredList]);

  const updateCardState = useCallback((_id: string, isCorrect: boolean) => {
    setCardStates(prevStates => {
      const card = prevStates[_id] || qnaList.find(item => item._id === _id) || { correctCount: 0, incorrectCount: 0 };
      return {
        ...prevStates,
        [_id]: {
          ...card,
          correctCount: (card.correctCount || 0) + (isCorrect ? 1 : 0),
          incorrectCount: (card.incorrectCount || 0) + (isCorrect ? 0 : 1),
          lastAnswered: new Date().toISOString()
        }
      };
    });
    countCorrect(_id, isCorrect);
  }, [qnaList, countCorrect]);

  const getRandomCard = useCallback(() => {
    if (filteredList.length > 0) {
      const randomIndex = Math.floor(Math.random() * filteredList.length);
      setCurrentCard(filteredList[randomIndex]);
      setCurrentIndex(randomIndex);
      setIsShow(false);
    }
  }, [filteredList]);

  const getNextCard = useCallback(() => {
    if (filteredList.length > 0) {
      const nextIndex = (currentIndex + 1) % filteredList.length;
      setCurrentCard(filteredList[nextIndex]);
      setCurrentIndex(nextIndex);
      setIsShow(false);
    }
  }, [filteredList, currentIndex]);

  const getPreviousCard = useCallback(() => {
    if (filteredList.length > 0) {
      const previousIndex = (currentIndex - 1 + filteredList.length) % filteredList.length;
      setCurrentCard(filteredList[previousIndex]);
      setCurrentIndex(previousIndex);
      setIsShow(false);
    }
  }, [filteredList, currentIndex]);

  const handleAnswer = useCallback(
    async (isCorrect: boolean) => {
      if (currentCard && !isShow) {
        setIsShow(true);
        updateCardState(currentCard._id, isCorrect);
        const pronunciation = await fetchPronunciation(currentCard.answer);
        setCardStates(prevStates => ({
          ...prevStates,
          [currentCard._id]: {
            ...prevStates[currentCard._id],
            pronunciation: pronunciation
          }
        }));
      }
    },
    [currentCard, isShow, updateCardState, fetchPronunciation]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        getRandomCard();
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();
        getNextCard();
      } else if (event.code === 'ArrowDown') {
        event.preventDefault();
        getPreviousCard();
      } else if (event.code === 'ArrowLeft' && currentCard && !isShow) {
        event.preventDefault();
        handleAnswer(false);
      } else if (event.code === 'ArrowRight' && currentCard && !isShow) {
        event.preventDefault();
        handleAnswer(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [getRandomCard, getNextCard, getPreviousCard, handleAnswer, currentCard, isShow]);

  return (
    <ListWrapper>
      <ControlPanel>
        <RadioButtonContainer>
          <RadioButton 
            type="radio" 
            id="AB" 
            name="order" 
            value="A:B" 
            checked={!isReversed} 
            onChange={() => setIsReversed(false)}
          />
          <RadioLabel htmlFor="AB">A:B</RadioLabel>
          <RadioButton 
            type="radio" 
            id="BA" 
            name="order" 
            value="B:A" 
            checked={isReversed} 
            onChange={() => setIsReversed(true)}
          />
          <RadioLabel htmlFor="BA">B:A</RadioLabel>
        </RadioButtonContainer>
        <CategoryDropdown 
          value={selectedCategory} 
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setIsShow(false);  // 카테고리 변경 시 답변 숨기기
          }}
        >
          {categories.map(category => (
            <option key={category} value={category}>
              {category === 'all' ? '모든 카테고리' : category}
            </option>
          ))}
        </CategoryDropdown>
        <RangeInputContainer>
          <RangeInput
            type="text"
            value={startRange}
            onChange={(e) => setStartRange(e.target.value)}
            placeholder="시작"
          />
          ~
          <RangeInput
            type="text"
            value={endRange}
            onChange={(e) => setEndRange(e.target.value)}
            placeholder="끝"
          />
        </RangeInputContainer>
      </ControlPanel>
      <CardWrapper>
        {isLoading ? (
          <LoadingMessage>카드를 불러오는 중...</LoadingMessage>
        ) : filteredList.length === 0 ? (
          <EmptyMessage>카드가 없습니다. 새로운 카드를 추가해주세요.</EmptyMessage>
        ) : currentCard && (
          <Card
            key={currentCard._id}
            onDelete={() => onDelete(currentCard._id)}
            _id={currentCard._id}
            incorrectCount={cardStates[currentCard._id]?.incorrectCount || currentCard.incorrectCount || 0}
            correctCount={cardStates[currentCard._id]?.correctCount || currentCard.correctCount || 0}
            question={isReversed ? currentCard.answer.split('\n')[0] : currentCard.question}
            answer={isReversed ? currentCard.question : currentCard.answer}
            fetchPronunciation={fetchPronunciation}
            countCorrect={updateCardState}
            lastAnswered={cardStates[currentCard._id]?.lastAnswered || currentCard.lastAnswered}
            height="100%"
            fontSize="18px"
            index={filteredList.indexOf(currentCard) + 1}
            showDelete={false}
            isShow={isShow}
            category={currentCard.category || 'none category'}
            setIsShow={setIsShow}
            pronunciation={cardStates[currentCard._id]?.pronunciation || null}
          />
        )}
      </CardWrapper>
      <ButtonContainer>
        <NavButton onClick={getPreviousCard} disabled={filteredList.length <= 1}>
          <ButtonText>이전</ButtonText>
          <KeyText>↓ 방향키</KeyText>
        </NavButton>
        <RefreshButton onClick={getRandomCard} disabled={false}>
          <ButtonText>랜덤</ButtonText>
          <KeyText>스페이스바</KeyText>
        </RefreshButton>
        <NavButton onClick={getNextCard} disabled={filteredList.length <= 1}>
          <ButtonText>다음</ButtonText>
          <KeyText>↑ 방향키</KeyText>
        </NavButton>
      </ButtonContainer>
    </ListWrapper>
  );
}

export default RandomCard;